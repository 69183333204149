<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>账户管理</p>
			<i>/</i>
			<p>角色列表</p>
		</nav>

		<div class="head_search_wrap">

			<div class="search_one_line">
				<div class="left">
					<el-form label-width="120px" @submit.native.prevent>
						<el-form-item label="角色名称:">
							<el-input class="input_medi" v-model="keyword" @keyup.enter.native="searchFn" clearable
								placeholder="请输入名称"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>

		</div>

		<div class="page_table">
			<div class="page_table_title">
				<div class="left">
					<p>角色列表</p>
				</div>
				<div class="right">
					<span class="span1" @click="addFn">新增</span>
				</div>
			</div>
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="id" label="角色ID" align="left"></el-table-column>
				<el-table-column prop="class_name" label="所属部门" align="center"></el-table-column>
				<el-table-column prop="role_name" label="角色名称" align="center"></el-table-column>
				<el-table-column prop="role_rank" label="角色等级" align="center"></el-table-column>
				<el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="140px">
					<template slot-scope="scope">
						<div class="operation">
							<span @click="modifyFn(scope.row)">修改</span>
							<span @click="jurisdictionFn(scope.row)">权限管理</span>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>

		</div>



	</div>
</template>

<script>
	
	export default {
		name: 'roleList',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
			}
		},
		components: {
			
		},
		created() {
			// 获取角色列表
			this.getRoleList();
		},
		methods: {
			// 重置
			resetFn() {
				this.currentPage1 = 1;
				this.keyword = '';
				this.getRoleList();
			},

			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getRoleList();
			},
			
			// 获取角色列表
			getRoleList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}admin/rolepaging`,
					data: {
						page: this.currentPage1,
						keyword: this.keyword,
					}
				}).then( res => {
					if(res.code == '0'){
						this.total = res.count;
						
						if(res.data.list){
							this.listData = res.data.list;
						}
						
					}
				})
			},

			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
				this.getRoleList();
			},

			// 修改
			modifyFn(row) {
				console.log(row)
				this.$router.push({
					path: '/roleEdit',
					query:{
						id: row.id,
					}
				})
			},

			// 新增
			addFn() {
				this.$router.push('/roleEdit');
			},
			
			// 权限管理
			jurisdictionFn(row){
				this.$router.push({
					path: '/rolePowerRun',
					query:{
						id: row.id,
					}
				})
			},
		}
	}
</script>
<style scoped lang="less">
	.operation {
		span {
			padding: 8px;
			font-size: 14px;
			color: #EE7B1D;
			cursor: pointer;
		}
	}
</style>
